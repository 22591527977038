import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { ReactNode } from "react-markdown/lib/react-markdown";

export interface SubheadingProps extends BoxProps {
  children: ReactNode;
}

export const Subheading = (props: SubheadingProps) => {
  const {
    children,
    fontSize = { base: "xl", md: "2xl" },
    fontWeight = "600",
    color = "text",
    as = "h3",
    ...restOfProps
  } = props;
  return (
    <Box
      {...restOfProps}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      as={as}
    >
      {children}
    </Box>
  );
};
