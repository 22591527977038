import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type buttonColorSchemes = "primary" | "secondary" | "white";
export interface ButtonProps extends ChakraButtonProps {
  children: ReactNode | ReactNode[];
  ffref?: any;
  colorScheme?: buttonColorSchemes;
}

const colorSchemeFontColorMap = {
  primary: "white",
  secondary: "white",
  white: "secondary.500"
};

export const Button = (props: ButtonProps) => {
  const { children, colorScheme = "primary" } = props;
  return (
    <ChakraButton
      ref={props.ffref}
      color={colorSchemeFontColorMap[colorScheme]}
      outline="none !important"
      _hover={{
        bg: "#81a721"
      }}
      bg={colorScheme}
      {...props}
    >
      {children}
    </ChakraButton>
  );
};
