import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { ReactNode } from "react-markdown/lib/react-markdown";
import { Heading } from "../Heading /Heading";
import { Subheading } from "../Subheading/Subheading";

export interface SectionHeaderProps extends BoxProps {
  title: string;
  subtitle?: string | ReactNode | ReactNode[];
}

export const SectionHeader = (props: SectionHeaderProps) => {
  const { title, subtitle, color, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Heading color={color || "primary"}>{title}</Heading>
      <Subheading color={color || "secondary"} mt={1}>
        {subtitle}
      </Subheading>
    </Box>
  );
};
