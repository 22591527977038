import { Button } from "@/components/Shared/Button/Button";
import { SectionHeader } from "@/components/Shared/SectionHeader/SectionHeader";
import { Box, BoxProps, Stack, Text } from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";

export interface OrderCreatedSectionProps extends BoxProps {
  courseId: number;
}

export const OrderCreatedSection = (props: OrderCreatedSectionProps) => {
  const { courseId, ...restOfProps } = props;

  return (
    <Box {...restOfProps}>
      <Stack spacing={6} alignItems="center" py={24}>
        <SectionHeader
          title="ĎAKUJEME VÁM ZA VAŠU OBJEDNÁVKU"
          subtitle={
            courseId === 1 ? (
              <>
                Kurz predpôrodnej prípravy <br /> (od 23-36 týždňa tehotenstva)
              </>
            ) : (
              "Laktačné poradenstvo"
            )
          }
          textAlign="center"
        />
        <Stack spacing={3} textAlign="center" maxW="3xl">
          <Text fontSize="xl" fontWeight="700">
            Skontrolujte si prosím Vašu e-mailovú schránku. Poslali sme Vám
            informačný e-mail.
          </Text>
          <Text as="p">
            Tešíme sa na Vás. Záleží nám na tom, aby ste sa u nás cítili
            príjemne a odchádzali spokojné.
          </Text>
        </Stack>
        <Link to={"/blog"}>
          <Button size="lg">Navštívte náš blog</Button>
        </Link>
      </Stack>
    </Box>
  );
};
